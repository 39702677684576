export default {
  pageHeroV1: {
    container: {
      height: '50vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '3.5rem'],
        letterSpacing: '3px',
        textTransform: 'uppercase',
        color: 'white',
        backgroundColor: 'rgb(194 194 194 / 50%)',
        padding: '2rem',
      },

      button: {
        variant: 'buttons.multiLocation',
      },
    },
  },
}
