export default {
  primary: {
    padding: '1rem',
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'light',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    ':hover': {
      backgroundColor: 'transparent',
      borderColor: 'primary',
      color: 'primary',
    },
  },
  secondary: {
    padding: '1rem',
    backgroundColor: 'transparent',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'light',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    ':hover': {
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'white',
    },
  },
}
