export default {
  footer: {
    '.logo': {
      maxHeight: '75px',
    },
    '.multiButtonContainer': {
      marginBottom: '1rem',
      border: 'none',
      a: {
        border: '2px solid',
        borderColor: 'primary',
        margin: '0.5rem',
        backgroundColor: 'primary',
        color: 'white',
        textTransform: 'uppercase',
        padding: '1rem 2rem',
        ':hover': {
          backgroundColor: 'transparent',
          color: 'black',
        },
      },
    },
  },

  contactForm: {
    order: '4',
    backgroundColor: '#f5f5f5',
    color: 'black',
    h2: {
      textTransform: 'uppercase',
      color: 'black',
      borderBottom: '2px white solid',
    },
    '.subtitle': {
      fontSize: '1.25rem',
      opacity: '0.7',
      color: 'black',
    },
    backgroundPosition: 'center center',
    padding: ['3.5rem 1rem', '', '5rem 1rem'],
    '.inputField, .textField ': {
      margin: '1rem 0rem',
      fontWeight: '300',
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'black',
      borderWidth: '1px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'black',
      '::placeholder': {
        color: 'black',
        opacity: '0.9',
      },
    },
    '.submitBtn': {
      variant: 'buttons.secondary',
      marginTop: '1rem',
      background: 'black',
      ':hover': {
        borderColor: 'white',
        color: 'white',
      },
    },
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'light',
    height: '75vh',

    '.slick-initialized .slick-slide > div': {
      height: '75vh',
    },

    '.hero_content_container': {
      left: '50%',
      marginLeft: '0rem',
      transform: 'translate(-50%,-50%)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '2rem',
      maxWidth: '767px',
    },
    '.title': { fontSize: ['3rem', '', '4rem', '5rem', '6rem'] },
    '.subtitle': { fontSize: ['1.1rem', '', '1.25rem', '1.75rem'] },
    '.ctaButton': {
      variant: 'buttons.secondary',
      marginBottom: '0rem',
    },

    '.slick-slide img': {
      filter: 'brightness(0.6)',
      height: '75vh',
    },
    'svg.slick-arrow': {
      color: 'white',
    },
  },

  homepageHeroShout: {
    display: 'none',
  },

  shoutSection: {
    padding: '3rem 0rem',
    maxHeight: ['', '', '60vh'],
    '.content': {
      padding: ['', '', '1rem', '3rem'],
    },
    '.title': {
      fontSize: ['1.25rem', '', '1.5rem', '1.75rem'],
      color: 'primary',
      textTransform: 'uppercase',
      borderBottom: 'dotted 2px',
      width: 'fit-content',
      paddingBottom: '1rem',
    },
    '.image': {},

    '.shoutCTA': {
      variant: 'buttons.primary',
    },
    '.date': {
      opacity: '0.7',
    },

    '.text': {
      marginBottom: '1rem',
      fontWeight: 'bold',
      fontSize: ['1.5rem', '', '1.7rem', '1.8rem'],
      maxWidth: 'unset',
    },
  },

  homepageServices: {
    padding: '4rem 0rem 2rem',

    '.menuSectionTitle ': {
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
      color: 'primary',
      textTransform: 'uppercase',
      borderBottom: 'dotted 2px',
      width: 'fit-content',
      paddingBottom: '1rem',
      margin: '2rem auto',
    },
    '.menuSectionDescription ': {
      fontSize: ['1.2rem'],
      opacity: '0.7',
      marginBottom: '2rem',
    },

    '.menuItemName': {
      color: 'primary',
      textTransform: 'uppercase',
      borderBottom: '2px solid',
      borderColor: 'primary',
      fontSize: ['1.1rem', '1.3rem', '1.3rem', '1.3rem'],
    },
  },

  homepageAbout: {
    '.title': {
      color: 'primary',
      textTransform: 'uppercase',
      borderBottom: 'dotted 2px',
      width: 'fit-content',
      paddingBottom: '1rem',
    },
    '.image': {
      maxHeight: ['55vh'],
    },
  },

  sideBySide2: {
    '.image': {
      maxHeight: ['55vh'],
    },
  },

  homepageContentBoxes: {
    '.image': {
      height: ['250px', '', '350px'],
      objectFit: 'cover',
    },
    '.title': {
      fontWeight: '500',
      marginBottom: '1rem',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    '.box': {},
  },

  homepageContentSection: {
    marginTop: '2rem',
    // background:
    //   "linear-gradient( rgb(0 0 0 / 0%),rgb(0 0 0 / 20%) ),url('https://res.cloudinary.com/gonation/image/upload/v1612986621/sites/pools-plus/Inground_Liner_Install.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      fontSize: ['', '', '4rem'],
      borderTop: '2px dotted',
      borderBottom: '2px dotted',
      borderColor: 'white',
      padding: '2rem 1rem',
      textTransform: 'uppercase',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      marginBottom: '0rem',
    },
  },

  // ? === about page ===

  aboutPageAbout: {
    '.title': {
      borderBottom: '2px dotted',
      borderColor: 'primary',
      padding: '2rem 1rem',
      textTransform: 'uppercase',
      color: 'primary',
    },
  },
  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
    },
    iframe: {
      display: 'none',
    },
    '.content_container': {
      width: ['100%', '100%', '100%', '100%'],
    },
  },
}
